import React from "react";

import { FontSizes, Icon, Link, Stack, Text } from "office-ui-fabric-react";
import { useStateContext } from "../state";
import { LocaleUtil } from "../utils";

function Welcome(props) {
  const [state] = useStateContext();

  return (
    <div className={props.className}>
      <Stack tokens={{ childrenGap: 5 }}>
        <Text
          styles={{
            root: { marginTop: "30px !important", fontSize: FontSizes.small },
          }}
        >
          {/* "PRODUCT SELECTION WIZARD" */}
          {LocaleUtil.getLabel(state.locale, "wizard.intro.title")}
        </Text>
        <Text className="media-header">
          {/* "Let us help you find the right product" */}
          {LocaleUtil.getLabel(state.locale, "wizard.intro.headline")}
        </Text>
        <div style={{ margin: "35px 0 10px 0" }}>
          <Link onClick={props.nextStep}>
            {/* "Get started" */}
            {LocaleUtil.getLabel(
              state.locale,
              "wizard.intro.button.next"
            )}{" "}
            <Icon
              iconName="ChevronRight"
              styles={{ root: { position: "relative", top: "2px" } }}
            />
          </Link>
        </div>
      </Stack>
    </div>
  );
}

export default Welcome;
