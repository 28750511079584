import React, { useState } from "react";

import {
  getTheme,
  mergeStyles,
  DefaultButton,
  Dropdown,
  FontSizes,
  Label,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from "office-ui-fabric-react";

import { CategoryUtil, LocaleUtil, OrientationUtil, StateUtil } from "../utils";
import { useStateContext } from "../state";
import validator from "validator";

function Step3(props) {
  const theme = getTheme();
  const [state, dispatch] = useStateContext();
  const [validated, setValidated] = useState(false);

  const isMakeModelOther =
    state.make.make_id === -1 || state.model.model_id === -1;

  const isWidthRequired = CategoryUtil.hasInputQuestionWidth(state.category);
  const isHeightRequired = CategoryUtil.hasInputQuestionHeight(state.category);
  const isDepthRequired = CategoryUtil.hasInputQuestionDepth(state.category);
  const isOrientationRequired = CategoryUtil.hasInputQuestionOrientation(
    state.category
  );
  const isWeightRequired = CategoryUtil.hasInputQuestionWeight(state.category);
  const isThreadedRequired = CategoryUtil.hasInputQuestionThreaded(
    state.category
  );

  const isWidthValid =
    !isWidthRequired || !validator.isEmpty(state.width || "");
  const isHeightValid =
    !isHeightRequired || !validator.isEmpty(state.height || "");
  const isDepthValid =
    !isDepthRequired || !validator.isEmpty(state.depth || "");
  const isDimensionsValid = isWidthValid && isHeightValid && isDepthValid;
  const isWeightValid =
    !isWeightRequired || !validator.isEmpty(state.weight || "");
  const isThreadedValid =
    !isThreadedRequired || typeof state.threaded === "boolean";
  const isOrientationValid =
    !(isOrientationRequired || state.model.multi_orientation) ||
    !validator.isEmpty(state.orientation || "");
  const isDetailsValid = isMakeModelOther
    ? isDimensionsValid &&
      isWeightValid &&
      isThreadedValid &&
      isOrientationValid
    : true;

  const isInputQuestionsRequired =
    (state.make.make_id === -1 || state.model.model_id === -1) &&
    CategoryUtil.hasInputQuestions(state.category);

  const wrapperClassName = mergeStyles({
    selectors: {
      ":not(:first-child)": { marginTop: "20px" },
      "& > *": { marginBottom: "20px" },
      "& .ms-ComboBox": { maxWidth: "350px" },
    },
  });

  const _onClickImperial = () => {
    if (state.units !== "i") {
      dispatch({
        type: "changeUnits",
        units: "i",
      });
    }
  };

  const _onClickMetric = () => {
    if (state.units !== "m") {
      dispatch({
        type: "changeUnits",
        units: "m",
      });
    }
  };

  const _onChangeWidthTextField = (event, text) => {
    if (validator.isEmpty(text) || validator.isFloat(text)) {
      dispatch({
        type: "changeWidth",
        width: text.trim(),
      });
    }
  };

  const _onChangeHeightTextField = (event, text) => {
    if (validator.isEmpty(text) || validator.isFloat(text)) {
      dispatch({
        type: "changeHeight",
        height: text.trim(),
      });
    }
  };

  const _onChangeDepthTextField = (event, text) => {
    if (validator.isEmpty(text) || validator.isFloat(text)) {
      dispatch({
        type: "changeDepth",
        depth: text.trim(),
      });
    }
  };

  const _onChangeWeightTextField = (event, text) => {
    if (validator.isEmpty(text) || validator.isFloat(text)) {
      dispatch({
        type: "changeWeight",
        weight: text.trim(),
      });
    }
  };

  const _onChangeOrientation = (event, option) => {
    dispatch({
      type: "changeOrientation",
      orientation: option ? option.key : null,
    });
  };

  const _onChangeThreaded = (event, option) => {
    dispatch({
      type: "changeThreaded",
      threaded: option ? option.key : null,
    });
  };

  const _onClickNext = () => {
    if (isDetailsValid) {
      props.nextStep();
    } else {
      setValidated(true);
    }
  };

  return (
    <div className={props.className}>
      <Stack tokens={{ childrenGap: 5 }}>
        <Text
          styles={{
            root: { marginTop: "30px !important", fontSize: FontSizes.small },
          }}
        >
          {/* "EQUIPMENT DETAILS" */}
          {LocaleUtil.getLabel(state.locale, "wizard.step3.title")}
        </Text>

        <Text className="media-header">
          {/* Enter a few details */}
          {LocaleUtil.getLabel(state.locale, "wizard.step3.headline")}
        </Text>
        <Text
          styles={{
            root: { marginTop: "20px !important", fontSize: FontSizes.small },
          }}
        >
          {/* Almost done, just a few more questions to help us recommend the best suited products for your application. */}
          {LocaleUtil.getLabel(state.locale, "wizard.step3.description")}
        </Text>
        <div className={wrapperClassName}>
          {isInputQuestionsRequired ? (
            <React.Fragment>
              <Stack horizontal>
                <DefaultButton
                  text={
                    // "Imperial"
                    LocaleUtil.getLabel(
                      state.locale,
                      "wizard.step3.measurements.imperial"
                    )
                  }
                  checked={state.units === "i"}
                  onClick={_onClickImperial}
                  styles={{
                    root: {
                      margin: "0px",
                      borderRight: "none",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                    },
                  }}
                />
                <DefaultButton
                  text={
                    // "Metric"
                    LocaleUtil.getLabel(
                      state.locale,
                      "wizard.step3.measurements.metric"
                    )
                  }
                  checked={state.units === "m"}
                  onClick={_onClickMetric}
                  styles={{
                    root: {
                      margin: "0px",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                    },
                  }}
                />
              </Stack>
              <div>
                <Label>
                  {
                    // `Dimensions ({0})`
                    LocaleUtil.getLabel(
                      state.locale,
                      "wizard.step3.field.dimensions.label",
                      [StateUtil.getDimensionsAbbrLabel(state)]
                    )
                  }
                </Label>
                <Stack
                  horizontal
                  tokens={{ childrenGap: 5 }}
                  styles={{ root: { maxWidth: 350 } }}
                  verticalAlign="top"
                >
                  {isWidthRequired ? (
                    <Stack.Item grow>
                      <TextField
                        placeholder={
                          // "Width"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.dimensions.width.placeholder"
                          )
                        }
                        onChange={_onChangeWidthTextField}
                        value={state.width}
                        errorMessage={
                          validated && !isWidthValid ? "\u00A0" : ""
                        }
                        styles={{ errorMessage: { position: "absolute" } }}
                      />
                    </Stack.Item>
                  ) : (
                    <></>
                  )}
                  {isWidthRequired && (isHeightRequired || isDepthRequired) ? (
                    <Stack.Item
                      styles={{
                        root: {
                          width: "10px",
                          paddingTop: "6px",
                          display: !CategoryUtil.hasInputQuestionWidth(
                            state.category
                          )
                            ? "none"
                            : undefined,
                        },
                      }}
                    >
                      <Text>x</Text>
                    </Stack.Item>
                  ) : (
                    <></>
                  )}
                  {isHeightRequired ? (
                    <Stack.Item grow>
                      <TextField
                        placeholder={
                          // "Height"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.dimensions.height.placeholder"
                          )
                        }
                        onChange={_onChangeHeightTextField}
                        value={state.height}
                        errorMessage={
                          validated && !isHeightValid ? "\u00A0" : ""
                        }
                        styles={{ errorMessage: { position: "absolute" } }}
                      />
                    </Stack.Item>
                  ) : (
                    <></>
                  )}
                  {isHeightRequired && isDepthRequired ? (
                    <Stack.Item
                      styles={{
                        root: {
                          width: "10px",
                          paddingTop: "6px",
                        },
                      }}
                    >
                      <Text>x</Text>
                    </Stack.Item>
                  ) : (
                    <></>
                  )}
                  {isDepthRequired ? (
                    <Stack.Item grow>
                      <TextField
                        placeholder={
                          // "Depth"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.dimensions.depth.placeholder"
                          )
                        }
                        onChange={_onChangeDepthTextField}
                        value={state.depth}
                        errorMessage={
                          validated && !isDepthValid ? "\u00A0" : ""
                        }
                        styles={{ errorMessage: { position: "absolute" } }}
                      />
                    </Stack.Item>
                  ) : (
                    <></>
                  )}
                  <Stack.Item>
                    <Text
                      styles={{
                        root: {
                          width: "55px",
                          paddingTop: "6px",
                          display: "inline-block",
                        },
                      }}
                    >
                      (
                      {state.units === "i"
                        ? // "inches"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.dimensions.imperial.label"
                          )
                        : // "cm"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.dimensions.metric.label"
                          )}
                      )
                    </Text>
                  </Stack.Item>
                </Stack>
                {validated && !isDimensionsValid ? (
                  <Text
                    block="true"
                    className="ms-TextField-errorMessage"
                    variant="small"
                    styles={{
                      root: { color: theme.palette.redDark, paddingTop: "5px" },
                    }}
                  >
                    {
                      /* All the dimensions must be entered. */
                      LocaleUtil.getLabel(
                        state.locale,
                        "wizard.step3.field.dimensions.message"
                      )
                    }
                  </Text>
                ) : null}
              </div>
              {isOrientationRequired ? (
                <Dropdown
                  label={
                    // "Orientation"
                    LocaleUtil.getLabel(
                      state.locale,
                      "wizard.step3.field.orientation.label"
                    )
                  }
                  options={state.orientations.map((orientation) => {
                    return {
                      key: orientation.code,
                      text: OrientationUtil.getOrientationName(
                        orientation,
                        state.locale_id
                      ),
                    };
                  })}
                  selectedKey={state.orientation}
                  onChange={_onChangeOrientation}
                  errorMessage={
                    validated && !isOrientationValid
                      ? // "A selection must be made."
                        LocaleUtil.getLabel(
                          state.locale,
                          "wizard.step3.field.orientation.message"
                        )
                      : ""
                  }
                  styles={{ root: { maxWidth: "350px" } }}
                ></Dropdown>
              ) : (
                <></>
              )}
              {isWeightRequired ? (
                <Stack
                  horizontal
                  tokens={{ childrenGap: 5 }}
                  styles={{
                    root: {
                      maxWidth: 350,
                    },
                  }}
                  verticalAlign="top"
                >
                  <Stack.Item grow>
                    <TextField
                      label={
                        // "Weight"
                        LocaleUtil.getLabel(
                          state.locale,
                          "wizard.step3.field.weight.label"
                        )
                      }
                      onChange={_onChangeWeightTextField}
                      value={state.weight}
                      errorMessage={
                        validated && !isWeightValid
                          ? // "A number must be entered."
                            LocaleUtil.getLabel(
                              state.locale,
                              "wizard.step3.field.weight.message"
                            )
                          : ""
                      }
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Text
                      styles={{
                        root: {
                          width: "55px",
                          paddingTop: "34px",
                          display: "inline-block",
                        },
                      }}
                    >
                      (
                      {state.units === "i"
                        ? // "lbs"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.weight.imperial.label"
                          )
                        : // "kg"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.weight.metric.label"
                          )}
                      )
                    </Text>
                  </Stack.Item>
                </Stack>
              ) : (
                <></>
              )}
              {isThreadedRequired ? (
                <div>
                  <Dropdown
                    label={
                      // "Threaded Inserts"
                      LocaleUtil.getLabel(
                        state.locale,
                        "wizard.step3.field.threaded.label"
                      )
                    }
                    placeholder={
                      // "Are threaded inserts available?"
                      LocaleUtil.getLabel(
                        state.locale,
                        "wizard.step3.field.threaded.placeholder"
                      )
                    }
                    options={[
                      {
                        key: true,
                        text:
                          // "Yes, they are available"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.threaded.option.yes"
                          ),
                      },
                      {
                        key: false,
                        text:
                          // "No, they are not available"
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.threaded.option.no"
                          ),
                      },
                    ]}
                    selectedKey={state.threaded}
                    onChange={_onChangeThreaded}
                    errorMessage={
                      validated && !isThreadedValid
                        ? //"A selection must be made."
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.threaded.message"
                          )
                        : ""
                    }
                    styles={{
                      root: { maxWidth: "350px", marginBottom: "20px" },
                    }}
                  ></Dropdown>
                  <div>
                    <Text
                      block="true"
                      styles={{
                        root: {
                          fontSize: FontSizes.small,
                        },
                      }}
                    >
                      {StateUtil.isCategoryCeilingWall(state)
                        ? // "Threaded inserts allow for mounting the speaker to a wall mount or ceiling mount system."
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.threaded.note.ceiling-wall"
                          )
                        : //"Threaded inserts allow for the installation of screw in feet."
                          LocaleUtil.getLabel(
                            state.locale,
                            "wizard.step3.field.threaded.note"
                          )}
                    </Text>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </React.Fragment>
          ) : state.model.multi_orientation ? (
            <React.Fragment>
              <Dropdown
                label={
                  // "Orientation"
                  LocaleUtil.getLabel(
                    state.locale,
                    "wizard.step3.field.orientation.label"
                  )
                }
                options={[
                  { key: "horizontal", text: "Horizontal" },
                  { key: "vertical", text: "Vertical" },
                ]}
                selectedKey={state.orientation}
                onChange={_onChangeOrientation}
                errorMessage={
                  validated && !isOrientationValid
                    ? //"A selection must be made."
                      LocaleUtil.getLabel(
                        state.locale,
                        "wizard.step3.field.orientation.message"
                      )
                    : ""
                }
                styles={{ root: { maxWidth: "350px" } }}
              ></Dropdown>
              <Text
                styles={{
                  root: {
                    fontSize: FontSizes.small,
                  },
                }}
              >
                {
                  // `Note: The typical orientation for your {0} is {1}.`
                  LocaleUtil.getLabel(
                    state.locale,
                    "wizard.step3.field.orientation.multi.note",
                    [
                      CategoryUtil.getCategoryName(
                        state.category,
                        state.locale_id
                      ).toLowerCase(),
                      state.model.default_orientation
                        ? StateUtil.getOrientationName(
                            state,
                            state.model.default_orientation
                          ).toLowerCase()
                        : "-",
                    ]
                  )
                }
              </Text>
            </React.Fragment>
          ) : (
            <></>
          )}
        </div>
        <div className="wizard-step-button-container">
          <DefaultButton
            style={{ marginRight: "5px" }}
            onClick={props.previousStep}
            text={LocaleUtil.getLabel(state.locale, "wizard.step3.button.back")}
          />
          <PrimaryButton
            onClick={_onClickNext}
            text={LocaleUtil.getLabel(state.locale, "wizard.step3.button.next")}
            disabled={false}
          />
        </div>
      </Stack>
    </div>
  );
}

export default Step3;
