import capitalize from "capitalize";

export const StateUtil = {
  isStep3Required: (state) => {
    return (
      StateUtil.isInputQuestionsRequired(state) || state.model.multi_orientation
    );
  },
  isInputQuestionsRequired: (state) => {
    return (
      (state.make.make_id === -1 || state.model.model_id === -1) &&
      CategoryUtil.hasInputQuestions(state.category)
    );
  },
  getDimensionsLabel: (state) => {
    //`${state.model.width} x ${state.model.height} x ${state.model.depth} inches (W x H x D)`}
    const dimensions =
      state.model.model_id === -1
        ? [
            {
              width: state.width,
              height: state.height,
              depth: state.depth,
              units: state.units,
            },
          ]
        : [
            {
              width: state.model.width,
              height: state.model.height,
              depth: state.model.depth,
              units: "i",
            },
            {
              width: Math.round(state.model.width * 2.54 * 10) / 10,
              height: Math.round(state.model.height * 2.54 * 10) / 10,
              depth: Math.round(state.model.depth * 2.54 * 10) / 10,
              units: "cm",
            },
          ];

    return dimensions
      .map(
        (dimensions, index) =>
          `${CategoryUtil.getDimensionsLabel(state.category, dimensions)} ${
            dimensions.units === "i"
              ? // "inches"
                LocaleUtil.getLabel(
                  state.locale,
                  "general.details.dimensions.imperial"
                )
              : // "cm"
                LocaleUtil.getLabel(
                  state.locale,
                  "general.details.dimensions.metric"
                )
          }${
            index === 0
              ? // (W x H x D)
                ` (${StateUtil.getDimensionsAbbrLabel(state)})`
              : ""
          }`
      )
      .join("\r\n");
  },
  getDimensionsAbbrLabel: (state) => {
    return CategoryUtil.getDimensions(state.category)
      .map((dimension) => {
        return LocaleUtil.getLabel(
          state.locale,
          `general.details.dimensions.${dimension}.abbr`
        );
      })
      .join(" x ");
  },
  getOrientationLabel: (state) => {
    return state.orientation || "";
  },
  getWeightLabel: (state) => {
    return state.model.model_id === -1
      ? `${state.weight} ${
          state.units === "i"
            ? // "lbs"
              LocaleUtil.getLabel(
                state.locale,
                "general.details.weight.imperial"
              )
            : // "kg"
              LocaleUtil.getLabel(state.locale, "general.details.weight.metric")
        }`
      : `${state.model.weight || 0} ${
          // "lbs"
          LocaleUtil.getLabel(state.locale, "general.details.weight.imperial")
        } (${Math.round((state.model.weight / 2.20462) * 10) / 10} ${
          // "kg"
          LocaleUtil.getLabel(state.locale, "general.details.weight.metric")
        })`;
  },
  isThreaded: (state) => {
    return state.model.model_id === -1 ? state.threaded : state.model.threaded;
  },
  getThreadedLabel: (state) => {
    return StateUtil.isThreaded(state)
      ? // "Yes"
        LocaleUtil.getLabel(state.locale, "general.details.threaded.yes")
      : // "No"
        LocaleUtil.getLabel(state.locale, "general.details.threaded.no");
  },
  isThreadSizeUndetermined: (state) => {
    return (
      state.model.model_id === -1 ||
      (state.model.thread_sizes || []).length === 0
    );
  },
  getThreadedSizeLabel: (state) => {
    return StateUtil.isThreadSizeUndetermined(state)
      ? // "Undetermined"
        LocaleUtil.getLabel(
          state.locale,
          "general.details.thread-size.undetermined"
        )
      : state.model.thread_sizes
          .map((threadSize) => {
            return ThreadSizeUtil.getThreadSizeDescription(
              threadSize,
              state.locale_id
            );
          })
          .join(", ");
  },
  isCommonThreadSizesAvailable: (state) => {
    return (
      state.make.make_id !== -1 && (state.make.thread_sizes || []).length > 0
    );
  },
  getCommonThreadSizeLabel: (state) => {
    const threadSizes = (state.make.thread_sizes || [])
      .map((threadSize) => {
        return threadSize.description;
      })
      .join(", ");

    // "**Note:** Common thread sizes used by this manufacturer are {0}. Your model is most likely to use one of these sizes."
    return LocaleUtil.getLabel(
      state.locale,
      "general.details.thread-size.note.common",
      [threadSizes]
    );
  },
  getThreadSizeDescription: (state, product) => {
    return product.threaded && !StateUtil.isCategoryCeilingWall(state)
      ? state.model.model_id === -1 ||
        (state.model.thread_sizes || []).length === 0
        ? [
            // "**Note:** The {0} include the 3 most common thread sizes which will match the majority of models. Alternate size threads are available upon request through IsoAcoustics distributors."
            LocaleUtil.getLabel(
              state.locale,
              "general.details.thread-size.note.undetermined",
              [product.name]
            ),
            // "Visit [IsoAcoustics](http://www.isoacoustics.com/threads) to learn about the {0} thread sizes and how to determine which size your model requires."
            LocaleUtil.getLabel(
              state.locale,
              "general.details.thread-size.help",
              [product.name]
            ),
          ].join("\r\n\r\n")
        : state.model.thread_sizes.some((threadSize) => {
            return !!product.thread_sizes.find((_threadSize) => {
              return _threadSize.thread_size_id === threadSize.thread_size_id;
            });
          })
        ? // "**Note:** Matching thread size is included with the %0 isolators."
          LocaleUtil.getLabel(
            state.locale,
            "general.details.thread-size.note.included",
            [product.name]
          )
        : [
            // "**Note:** The required thread size for this model is not included with the {0}, but are available upon request through IsoAcoustics distributors."
            LocaleUtil.getLabel(
              state.locale,
              "general.details.thread-size.note.not-included",
              [product.name]
            ),
            // "Visit [IsoAcoustics](http://www.isoacoustics.com/threads) to learn about the {0} thread sizes and how to determine which size your model requires."
            LocaleUtil.getLabel(
              state.locale,
              "general.details.thread-size.help",
              [product.name]
            ),
          ].join("\r\n\r\n")
      : "";
  },
  getRecommendationLabel: (state, recommendation) => {
    return `${recommendation.name} ${
      recommendation.quantities.length
        ? // "(QTY {0})"
          LocaleUtil.getLabel(
            state.locale,
            "general.details.recommendation.quantity",
            [recommendation.quantities.join(",")]
          )
        : ""
    }`;
  },

  getOrientationName: (state, orientation) => {
    const _orientation = state.orientations.find((_orientation) => {
      return _orientation.id === orientation.id;
    });
    return OrientationUtil.getOrientationName(_orientation, state.locale_id);
  },

  isCategorySubwoofer: (state) => {
    return state.category.code === "subwoofer";
  },
  isCategoryCeilingWall: (state) => {
    return state.category.code === "pro_ceiling_wall";
  },
  isMakeOther: (state) => {
    return state.make.make_id === -1;
  },
  isModelOther: (state) => {
    return state.model.model_id === -1;
  },
  isOrientationRequired: (state) => {
    return CategoryUtil.hasInputQuestionOrientation(state.category);
  },
  isWeightRequired: (state) => {
    return CategoryUtil.hasInputQuestionWeight(state.category);
  },
  isThreadedRequired: (state) => {
    return CategoryUtil.hasInputQuestionThreaded(state.category);
  },
  getDetails: (state, recommendations) => {
    // used by results page to populate details section;
    //  also used by product inquiry to send email
    let details = {
      category: CategoryUtil.getCategoryName(state.category, state.locale_id),
      make: StateUtil.isMakeOther(state)
        ? `${
            // "Other"
            LocaleUtil.getLabel(
              state.locale,
              "general.details.make.other.label"
            )
          }: ${state.makeName}`
        : state.make.name,
      model: StateUtil.isModelOther(state)
        ? `${
            // "Other"
            LocaleUtil.getLabel(
              state.locale,
              "general.details.model.other.label"
            )
          }: ${state.modelName}`
        : state.model.name,
      dimensions: StateUtil.getDimensionsLabel(state),
      orientation:
        (StateUtil.isModelOther(state) &&
          StateUtil.isOrientationRequired(state)) ||
        state.model.multi_orientation
          ? capitalize(StateUtil.getOrientationLabel(state))
          : null,
      weight: StateUtil.isWeightRequired(state)
        ? StateUtil.getWeightLabel(state)
        : null,
      threaded: StateUtil.isThreadedRequired(state)
        ? StateUtil.getThreadedLabel(state)
        : null,
      "thread-size": null,
      note: null,
      additional: null,
    };

    if (details.threaded !== null) {
      details["thread-size"] = StateUtil.isThreaded(state)
        ? StateUtil.getThreadedSizeLabel(state)
        : null;
    }

    if (details["thread-size"]) {
      details.note =
        StateUtil.isThreadSizeUndetermined(state) &&
        StateUtil.isCommonThreadSizesAvailable(state)
          ? StateUtil.getCommonThreadSizeLabel(state)
          : null;
    }

    if (StateUtil.isCategorySubwoofer(state) && StateUtil.isModelOther(state)) {
      const email = recommendations
        ? StateUtil.getDownfiringEmail(state, recommendations)
        : null;

      // "The following recommendations are based on a front or side firing subwoofer. Please contact %0 if your subwoofer is downfiring."
      details.additional = LocaleUtil.getLabel(
        state.locale,
        "general.details.subwoofer.downfiring",
        [
          email
            ? `[IsoAcoustics](mailto:info@isoacoustics.com?subject=${email.subject}&body=${email.body})`
            : "IsoAcoustics",
        ]
      );
    }

    return details;
  },
  getDetailsVerbose: (state) => {
    let details = StateUtil.getDetails(state);
    let labels = Object.getOwnPropertyNames(details);
    return labels
      .filter((label) => details[label] !== null)
      .map((label) => {
        let newline = ["model", "thread-size"].includes(label) ? "\r\n" : "";
        let tabs = label.length + 1 > 8 ? "\t" : "\t\t";
        return `${LocaleUtil.getLabel(
          state.locale,
          `general.details.${label}.label`
        )}:${tabs}${details[label]
          .replace("**Note:** ", "")
          .replace(/\[(.*?)\]\((.+?)\)/g, "$1") // https://regex101.com/r/tZ6yK9/1
          .replace(/(\r\n)/g, "\r\n\t\t\t")}${newline}`;
      })
      .join("\r\n");
  },
  getDownfiringEmail: (state, recommendations) => {
    // [IsoAcoustics](mailto:info@isoacoustics.com?subject=IsoAcoustics%20Downfiring%20Subwoofer%20Inquiry)
    return {
      subject: encodeURIComponent(
        // "IsoAcoustics Downfiring Subwoofer"
        LocaleUtil.getLabel(
          state.locale,
          "general.details.email.subject.downfiring"
        )
      ),
      body: StateUtil.getEmailBody(state, recommendations),
    };
  },
  getInquiryEmail: (state, recommendations) => {
    return {
      subject: encodeURIComponent(
        // "IsoAcoustics Product Inquiry"
        LocaleUtil.getLabel(
          state.locale,
          "general.details.email.subject.inquiry"
        )
      ),
      body: StateUtil.getEmailBody(state, recommendations),
    };
  },
  getEmailBody: (state, recommendations) => {
    return encodeURIComponent(
      // "[Submit email to IsoAcoustics to review. Please include your Name and Country. Feel free to attach photos and provide details.]\r\n\r\nRecommendations ({0})\r\n----------------------------\r\n{1}\r\n\r\nDetails\r\n----------------------------\r\n{2}"
      LocaleUtil.getLabel(state.locale, "general.details.email.body", [
        recommendations.length,
        recommendations
          .map((recommendation) => {
            return StateUtil.getRecommendationLabel(state, recommendation);
          })
          .join("\r\n"),
        StateUtil.getDetailsVerbose(state),
      ])
    );
  },
};

export const CategoryUtil = {
  getCategoryName: (category, locale_id) => {
    const resource = LocaleUtil.getResource(category, locale_id);
    return resource ? resource.name : "-";
  },
  hasInputQuestions: (category) => {
    return category.input_questions && category.input_questions.length > 0;
  },
  hasInputQuestion: (category, code) => {
    return !!(category.input_questions || []).find((inputQuestion) => {
      return inputQuestion.code === code;
    });
  },
  hasInputQuestionWidth: (category) => {
    return CategoryUtil.hasInputQuestion(category, "width");
  },
  hasInputQuestionHeight: (category) => {
    return CategoryUtil.hasInputQuestion(category, "height");
  },
  hasInputQuestionDepth: (category) => {
    return CategoryUtil.hasInputQuestion(category, "depth");
  },
  hasInputQuestionOrientation: (category) => {
    return CategoryUtil.hasInputQuestion(category, "orientation");
  },
  hasInputQuestionWeight: (category) => {
    return CategoryUtil.hasInputQuestion(category, "weight");
  },
  hasInputQuestionThreaded: (category) => {
    return CategoryUtil.hasInputQuestion(category, "threaded");
  },
  getDimensions: (category) => {
    return ["width", "height", "depth"].reduce((list, dimension) => {
      if (CategoryUtil.hasInputQuestion(category, dimension)) {
        list.push(dimension);
      }
      return list;
    }, []);
  },
  getDimensionsLabel: (category, dimensions) => {
    return CategoryUtil.getDimensions(category)
      .map((dimension) => {
        return dimensions[dimension];
      })
      .join(" x ");
  },
};

export const ModelUtil = {
  getModelComments: (model, locale_id) => {
    const resource = LocaleUtil.getResource(model, locale_id);
    return resource ? resource.comments : "";
  },
};

export const ProductUtil = {
  getProductDescription: (product, locale_id) => {
    const resource = LocaleUtil.getResource(product, locale_id);
    return resource ? resource.description : "-";
  },
  getProductUrl: (product, locale_id) => {
    const resource = LocaleUtil.getResource(product, locale_id);
    return resource ? resource.uri : "-";
  },
};

export const AdapterUtil = {
  getAdapterName: (adapter, locale_id) => {
    const resource = LocaleUtil.getResource(adapter, locale_id);
    return resource ? resource.name : "-";
  },
  getAdapterDescription: (adapter, locale_id) => {
    const resource = LocaleUtil.getResource(adapter, locale_id);
    return resource ? resource.description : "-";
  },
};

export const OrientationUtil = {
  getOrientationName: (orientation, locale_id) => {
    const resource = LocaleUtil.getResource(orientation, locale_id);
    return resource ? resource.name : "-";
  },
};

export const ThreadSizeUtil = {
  getThreadSizeDescription: (threadSize, locale_id) => {
    const resource = LocaleUtil.getResource(threadSize, locale_id);
    const extra = resource ? resource.extra : "";
    return extra
      ? `${threadSize.description} ${extra}`
      : threadSize.description;
  },
};

export const LocaleUtil = {
  getDefaultLocale: (locales) => {
    const locale = window.navigator.language;
    const language = locale.split("-")[0];
    // use the browser language as the default locale or english if not available
    const defaultLocale = LocaleUtil.getLocaleByLanguage(locales, language);
    return defaultLocale || LocaleUtil.getLocaleByLanguage(locales, "en");
  },
  getLocaleByLanguage: (locales, code) => {
    return locales.find((locale) => {
      return locale.language.code === code;
    });
  },
  getResource: (_object, locale_id) => {
    const resource = (
      _object && _object.resources ? _object.resources : []
    ).find((resource) => {
      return resource.locale_id === locale_id;
    });
    return resource;
  },
  getLabel: (locale, key, params = []) => {
    return (locale.resources[key] || "%RESOURCE_MISSING%").replace(
      /({\d})/g,
      function (i) {
        return params[i.replace(/{/, "").replace(/}/, "")];
      }
    );
  },
};
